import {
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Button } from "reactstrap";
import { AuthService } from "../../services/auth.service";
import { OrderService } from "../../services/order.service";
import IOrder from "../../types/order.type";
import IPostDataTransferResponse from "../../types/postdatatransferresponse.type";

export const PostDataTransfer = (): JSX.Element => {
  const [redirect, setRedirect] = useState<string | undefined>(undefined);
  // if order data is being loaded
  const [dataLoading, setIsDataLoading] = useState(false);
  // other basic requests
  const [loading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [postDataReponse, setPostDataResponse] = useState<
    IPostDataTransferResponse | undefined
  >(undefined);
  const [availableResponseFile, setAvailableResponseFile] = useState<
    string | undefined
  >(undefined);

  const loadOrders = async () => {
    setIsDataLoading(true);
    try {
      const orders = await OrderService.getOrdersShippedToday();
      setOrders(orders);
      setErrorMessage(undefined);
    } catch (e) {
      setErrorMessage("Error fetching order items");
      console.error("Error fetching order items", e);
    }
    setIsDataLoading(false);
  };

  const loadResponse = async () => {
    try {
      const responseFile = await OrderService.getPostXmlResponse();
      setAvailableResponseFile(responseFile);
    } catch (e) {
      setErrorMessage("Error fetching response file");
      console.error("Error fetching response file", e);
    }
  };

  useEffect(() => {
    const authenticated = AuthService.isUserAuthenticated();
    if (!authenticated) {
      setRedirect("/");
      return;
    }

    loadOrders();
    loadResponse();
  }, []);

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  const uploadFile = async () => {
    setIsLoading(true);

    try {
      const response = await OrderService.postShippedTodayOrders();
      setPostDataResponse(response);
      setErrorMessage(undefined);
      setAvailableResponseFile(undefined);
    } catch (e) {
      setPostDataResponse(undefined);
      setErrorMessage("Error posting orders");
      console.error("Error posting orders", e);
    }

    setIsLoading(false);
  };

  const downloadFile = async () => {
    setIsLoading(true);

    try {
      await OrderService.getPostXmlResponseFile();
    } catch (e) {
      setErrorMessage("Error downloading PDF");
      console.error("Error downloading PDF", e);
    }

    setIsLoading(false);
  };

  let tableBody = (
    <TableRow
      key="loading"
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell>
        <CircularProgress />
      </TableCell>
    </TableRow>
  );

  if (!dataLoading) {
    tableBody = (
      <>
        {orders.map((row) => (
          <TableRow
            key={row.id}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell component="th" scope="row">
              {row.number}
            </TableCell>
            <TableCell style={{ whiteSpace: "pre" }}>
              {row.addressLines}
            </TableCell>
            <TableCell>{row.tracking}</TableCell>
          </TableRow>
        ))}
      </>
    );
  }

  let errorMessageElem = undefined;
  if (errorMessage) {
    errorMessageElem = (
      <div className="alert alert-danger" role="alert">
        {errorMessage}
      </div>
    );
  }

  return (
    <div>
      <div className="title-sync-grid">
        <h1>Post Data Transfer</h1>
      </div>
      {errorMessageElem}
      <div style={{ padding: "10px" }} />
      <Stack direction="column" spacing={2} useFlexGap={true}>
        <div>
          <Button disabled={loading} onClick={uploadFile}>
            Upload XML File to Post
          </Button>
        </div>
        <Stack direction="row" spacing={5} style={{ alignItems: "center" }}>
          <div>Posted Filename:</div>
          <div>
            <b>{postDataReponse?.postedFileName ?? "-"}</b>
          </div>
        </Stack>
        <Stack direction="row" spacing={5} style={{ alignItems: "center" }}>
          <div>Info:</div>
          <div>
            <b>{postDataReponse?.message ?? "-"}</b>
          </div>
        </Stack>
      </Stack>
      <Divider style={{ margin: "20px" }} />
      <Stack direction="column" spacing={1} useFlexGap={true}>
        <h3>Response</h3>
        <Stack direction="row" spacing={5} style={{ alignItems: "center" }}>
          <div>Available Response File:</div>
          <div>
            <b>{availableResponseFile ?? "-"}</b>
          </div>
        </Stack>
        <div>
          <Button
            disabled={loading || !availableResponseFile}
            onClick={downloadFile}
          >
            Download PDF
          </Button>
        </div>
      </Stack>
      <Divider style={{ margin: "20px" }} />
      <Stack spacing={3}>
        <div>
          <h3>Applicable Orders</h3>
        </div>
        <div>
          The following <b>{orders.length}</b> orders will be included in the
          XML File.
        </div>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Order Number</b>
                </TableCell>
                <TableCell>
                  <b>Address</b>
                </TableCell>
                <TableCell>
                  <b>Tracking</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableBody}</TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </div>
  );
};
